import {
  Component,
  inject,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { CardComponent } from 'src/app/components/shared/card/card.component';
import { CardTitleComponent } from 'src/app/components/shared/card-title/card-title.component';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { ExpanderAnimationComponent } from 'src/app/components/shared/expander-animation/expander-animation.component';

@Component({
  selector: 'app-expandable-card',
  standalone: true,
  imports: [
    CommonModule,
    AppMaterialModule,
    CardComponent,
    CardTitleComponent,
    RowComponent,
    ColComponent,
    ExpanderAnimationComponent,
  ],
  templateUrl: './expandable-card.component.html',
  styleUrls: ['./expandable-card.component.scss'],
})
export class ExpandableCardComponent implements OnInit {
  public isExpanded: boolean;

  @Input() initiallyOpen: boolean = false;
  @Input() class: string;
  viewRef = inject(ViewContainerRef);
  id: string;

  ngOnInit() {
    this.isExpanded = this.initiallyOpen;
    this.id = self.crypto.randomUUID();
    this.viewRef.element.nativeElement.setAttribute('class', '');
  }
}
