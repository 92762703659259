<app-card [class]="'expander ' + class" [class.pb-0]="!isExpanded">
  <ng-content></ng-content>

  <button
    action
    mat-icon-button
    color="primary"
    class="button"
    aria-label="Ausklappen"
    (click)="isExpanded = !isExpanded"
    [attr.aria-expanded]="isExpanded"
    [attr.aria-controls]="id"
  >
    <mat-icon class="icon" [class.icon--rotated]="isExpanded">
      expand_more
    </mat-icon>
  </button>

  <app-expander-animation [isExpanded]="isExpanded" [id]="id">
    <ng-content select="[content]"></ng-content>
  </app-expander-animation>
</app-card>
